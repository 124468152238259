<!--
 * @Description: homePage 首页
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-02 15:01:14
 * @LastEditors: zhoucheng
-->
<!--  -->
<template>
  <div class='main'>
    <div class="main-content">
      <div class="top">
        <div class="title">渝悦停</div>
        <van-image :src="require('@/assets/homePage/index/topBG.png')"></van-image>
      </div>
      <div class="placeholder"
           :style="isMoreMenu ? 'height: 220px':''"></div>
      <van-swipe>
        <van-swipe-item v-for="item in advList"
                        :key="item.advertiseId">
          <van-image style="margin: 0 10px;width:100%;height:100px;border-radius: 5px;overflow: hidden;"
                     fit="fill"
                     :src="$imgBaseUrl + item.picUrl"></van-image>
        </van-swipe-item>
        <template #indicator>
          <div></div>
        </template>
      </van-swipe>
      <div class="list-title">
        <div class="left">
          <van-image style="margin: 0 5px 0 10px;width: 20px"
                     :src="require('@/assets/homePage/index/new.png')"></van-image>
          <span>最新资讯</span>
        </div>
        <div class="more">更多</div>
      </div>
      <div class="content"
           :style="isMoreMenu ? 'height: calc(100% - 583px)':''">
        <van-list v-model="loading"
                  :finished="finished"
                  :error.sync="listError"
                  error-text="请求失败，点击重新加载"
                  finished-text="没有更多了"
                  offset="10"
                  @load="onLoad">
          <div class="list-content"
               v-for="item in information"
               :key="item.contentId">
            <van-image style="width: 25%;height: 70px"
                       fit="cover"
                       :src="$imgBaseUrl + item.url"
                       @click="toPage('/informationDetails',{...item})"></van-image>
            <div class="content"
                 @click="toPage('/informationDetails',{...item})">
              <div class="content-top">{{item.contentName}}</div>
              <div class="content-foot">
                <span>{{item.createTime | filtDate}}</span>
                <span>渝悦停</span>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <div class="menu-box"
         :style="isMoreMenu ? 'height: 265px':'height: 192px;'">
      <div class="menu-row">
        <div class="menu-item"
             @click="toPage('/parForParking')">
          <van-image style="width: 40px;"
                     :src="require('@/assets/homePage/index/tingcejiaofei.png')"></van-image>
          <span class="menu-text">停车缴费</span>
        </div>
        <div class="menu-item"
             @click="toPage('/aroundParkSpace')">
          <van-image style="width: 40px;"
                     :src="require('@/assets/homePage/index/zoubianbowei.png')"></van-image>
          <span class="menu-text">周边泊位</span>
        </div>
        <div class="menu-item"
             @click="toPage('/parkingRecord')">
          <van-image style="width: 40px;"
                     :src="require('@/assets/homePage/index/tingcejilu.png')"></van-image>
          <span class="menu-text">停车记录</span>
        </div>
        <div class="menu-item"
             @click="toPage('/payInfo')">
          <van-image style="width: 40px;"
                     :src="require('@/assets/homePage/index/zhifuxinxi.png')"></van-image>
          <span class="menu-text">支付信息</span>
        </div>
      </div>
      <div class="menu-row">
        <div class="menu-item"
             @click="toPage('')">
          <van-image style="width: 40px;"
                     :src="require('@/assets/homePage/index/gongxiangtingce.png')"></van-image>
          <span class="menu-text">共享停车</span>
        </div>
        <div class="menu-item"
             @click="toPage('')">
          <van-image style="width: 40px;"
                     :src="require('@/assets/homePage/index/yvyuetingce.png')"></van-image>
          <span class="menu-text">预约停车</span>
        </div>
        <div class="menu-item"
             @click="toPage('')">
          <van-image style="width: 40px;"
                     :src="require('@/assets/homePage/index/congdianfuwu.png')"></van-image>
          <span class="menu-text">充电服务</span>
        </div>
        <div class="menu-item"
             @click="toPage('')">
          <van-image style="width: 40px;"
                     :src="require('@/assets/homePage/index/jiayoufuwu.png')"></van-image>
          <span class="menu-text">加油服务</span>
        </div>
      </div>
      <div class="menu-row"
           :style="isMoreMenu?'opacity: 1;':'opacity: 0;'">
        <div class="menu-item"
             @click="toPage('',{},true)">
          <van-image style="width: 40px;"
                     :src="require('@/assets/homePage/index/xicefuwu.png')"></van-image>
          <span class="menu-text">洗车服务</span>
        </div>
        <div class="menu-item"
             @click="toPage('',{},true)">
          <van-image style="width: 40px;"
                     :src="require('@/assets/homePage/index/xiucefuwu.png')"></van-image>
          <span class="menu-text">修车服务</span>
        </div>
        <div class="menu-item"
             @click="toPage('',{},true)">
          <van-image style="width: 40px;"
                     :src="require('@/assets/homePage/index/nianshenfuwu.png')"></van-image>
          <span class="menu-text">年审服务</span>
        </div>
        <div class="menu-item"
             @click="toPage('',{},true)">
          <van-image style="width: 40px;"
                     :src="require('@/assets/homePage/index/xinnengyuan.png')"></van-image>
          <span class="menu-text">新能源租赁</span>
        </div>
      </div>
      <div :class="isMoreMenu? 'more2': 'more'">
        <div class="img-box"
             @click="changeMenu">
          <van-image style="width: 12px"
                     :src="require('@/assets/homePage/index/arrow.png')"></van-image>
        </div>
      </div>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item name="homePage"
                       icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item name="park"
                       icon="search"
                       @click="toPage('/carParking')">停车</van-tabbar-item>
      <van-tabbar-item name="myself"
                       icon="friends-o"
                       @click="toPage('/myself')">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      active: 'homePage',
      finished: false,
      loading: true,
      listError: false,
      information: [],
      // 资讯请求参数
      queryInfo: {
        contentStatus: 2,
        pageNum: 0,
        pageSize: 6
      },
      // 轮播图列表
      advList: [],
      isMoreMenu: false,
      a: 10
    }
  },
  // 监听属性 类似于data概念
  computed: {
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    if (window.webkit) {
      window.webkit.messageHandlers.login.postMessage('')
      this.finished = false
      this.loading = true
      window.onLoad = () => {
        this.onLoad()
        this.getAdvertiseList()
      }
    } else if (window.android) {
      this.onLoad()
      this.getAdvertiseList()
    } else {
      this.onLoad()
      this.getAdvertiseList()
    }
  },
  filters: {
    filtDate (data) {
      return data.split(' ')[0]
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () {
    // console.log('keep-alive缓存')
  }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    onLoad () {
      this.queryInfo.pageNum++
      this.finished = false
      this.$homeIndex.queryInformation(this.queryInfo).then(res => {
        this.information = [...this.information, ...res.resultEntity.list]
        if (this.information.length >= res.resultEntity.total) {
          this.finished = true
          this.loading = false
        } else {
          this.finished = false
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
        this.listError = true
      })
      /**
       * content: "这是一条昨天的咨询"
          contentId: "47"
          contentName: "资讯1"
          contentStatus: "1"
          createTime: "2021-04-23 04:30:51"
          delFlag: 0
          editFlag: true
          lastModifyTime: "2021-04-24 08:03:43"
          remark: "应该咋样呢"
          sort: 0
          status: "未发布"
       */
    },
    // 获取录播图
    getAdvertiseList () {
      const info = {
        advStatus: 1,
        pageNum: 1,
        pageSize: 1000
      }
      this.$homeIndex.queryAdvertiseList(info).then(res => {
        this.advList = res.resultEntity.list
      })
    },

    // 菜单更多按钮点击
    changeMenu () {
      this.isMoreMenu = !this.isMoreMenu
    },
    // 路由跳转（路径，参数，是否为菜单的第三行）
    toPage (path, query, row3) {
      // 如果是菜单第三行，且是折叠状态，则不进行跳转
      if (row3 && !this.isMoreMenu) {
        return
      }
      if (!path) {
        this.$toast({
          position: 'top',
          message: '功能暂未开放'
        })
      } else {
        this.$router.push({ path, query })
      }
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.main {
  height: 100%;
  .main-content {
    height: calc(100% - 50px);
    .top {
      position: relative;
      height: 240px;
      .title {
        position: absolute;
        top: 3rem;
        width: 100%;
        text-align: center;
        color: #ffffff;
        font-size: 19px;
        font-weight: Medium;
        line-height: 23px;
        z-index: 10;
      }
    }
  }
  .placeholder {
    height: 150px;
    background: #f5f6f7;
    transition: height 0.3s ease;
  }
  .list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 33px;
    background: #ffffff;
    border-bottom: solid 1px #e7e8e8;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333333;
      font-size: 17px;
      font-weight: Bold;
    }
    .more {
      margin-right: 10px;
      padding: 2px 10px;
      border-radius: 5px;
      border: 1px solid #999999;
      font-size: 13px;
      font-weight: Regular;
      color: #999999;
    }
  }
  .content {
    height: calc(100% - 512px);
    overflow: auto;
    transition: height 0.3s ease;
    .list-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px 10px;
      background: #ffffff;
      border-bottom: 1px solid #e7e8e8;
      box-sizing: border-box;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        width: 75%;
        padding-left: 15px;
        box-sizing: border-box;
        .content-top {
          width: 100%;
          color: #333333;
          font-size: 16px;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .content-foot {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          text-align: right;
          color: #999999;
          font-size: 13px;
        }
      }
    }
  }
  .menu-box {
    position: fixed;
    top: 190px;
    left: 2.5%;
    width: 95%;
    padding: 20px 0px 10px 0px;
    background: #ffffff;
    border-radius: 5px;
    z-index: 100;
    box-sizing: border-box;
    transition: height 0.3s ease;
    overflow: hidden;
    .menu-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      transition: opacity 0.3s ease;
      .menu-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 25%;
        .menu-text {
          margin-top: 5px;
          font-size: 15px;
          color: #9b9b9b;
        }
      }
    }
    .more {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 5px;
    }
    .more2 {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 5px;
      .van-image {
        transform: rotatez(180deg);
      }
    }
    .img-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      width: 20px;
    }
  }
}
</style>
